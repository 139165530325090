import React from 'react';

const Wave = (props) => {
  const customClass = props.position ? props.position : '';
  return (
    <svg className={`wave ${customClass} animate-on-scroll wipe-right`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 230">
      <path className="pink" d="M1319.27,156c72.33-6.51,154.62-36.59,210-69.81H1440c-43.72,27.07-66.21,36.12-149.76,45.47C1212.35,140.4,713.61,99,286,38.79c-60.37-8.5-186.82-9.32-246.39,3.75Q-103.14,73.88-203.78,158.33h45.59C-82.19,104.45.4,72.06,90.87,64.11c57.18-5,144.54-10.34,231.94,5.35C417.11,86.4,1137.77,172.38,1319.27,156Z"/>
      <path className="yellow" d="M340.14,103.2C273,89.32,160.34,85.72,110.63,89.88c-71.13,6-138.86,31.12-204,67.45h-65.46Q-51.83,85,66.87,67.06c57.54-8.72,158.64-13.15,239.36-.35,239.12,37.9,741.17,83.08,816.34,87.76,88.38,5.49,176.54,2.41,203.2.18,71.14-6,138.86-31.12,204-67.44h65.47c-71.35,48.19-138.48,110.49-422.42,104.65C876.75,185.77,386.05,112.68,340.14,103.2Z"/>
      <path className="blue" d="M1396.84,202c96.16-15.73,176.31-59.48,243.4-115.78h-45.6c-76,53.88-159.2,81.06-249.06,94.22-33.62,4.92-126.85,11.66-228.85,2.47-76.51-6.9-682-64.27-795-83.78-8.18-1.41-153.17-16.61-224-8.33C25.6,99.24-37.43,125.12-92.77,158.34h83.1C52.26,123.5,114.19,110.65,300,128.18,606.64,157.11,1138.89,244.19,1396.84,202Z"/>
    </svg>
  )
}

export default Wave
