import React from 'react';

const Wave4 = (props) => {
  return (
    <svg className="wave wave--4 animate-on-scroll wipe-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 230">
      <path className="yellow" d="M1457.5,57.4c-98.6-2.1-182.9,4.2-333.2,17.8C873.8,98.9,663,59.9,517.4,26.6c-27.3-6.2-54.3-11-81.9-13.2
	c-45.4-4.6-89.7,2.7-134.9,3.3C256,17.6,200.3,3.4,152,1.8C101.8-1.2,50.5-0.3,0,3.1v25.7c35.2-2.3,70.6-3.5,105.4-3
	c74.8-1.8,139.7,18.1,207.8,16.2c32.2-1.2,63.9-5.3,96.3-4.4c36.3,0.8,72.3,6.5,107.8,14.6c112.2,28,159.7,48,254.2,68.4
	c190,48.8,493.3,24.2,690.4,22.3V57.6L1457.5,57.4z"/>
    </svg>
  )
}

export default Wave4