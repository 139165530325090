import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Section from '../../components/Layout/Section/Section';
import InnerContainer from '../../components/Layout/InnerContainer/InnerContainer';
import Main from '../../components/Layout/Main';
import CoverImage from '../../components/CoverImage';
import ThreeStar from '../../components/Dividers/ThreeStar'
import Star from '../../components/Dividers/star'
import SingleImageWave from '../../components/Dividers/single-image-wave'
import Helmet from 'react-helmet';

import './overview-page.scss';

const Overview = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query homeQuery {
      markdownRemark(frontmatter: { pageId: { eq: "overview-page" } }) {
        frontmatter {
          components {
            type
            headline
            body {
              line
            }
            attr
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
        }
      }
    }
  `)

  function getComponent(name) {
    const components = markdownRemark.frontmatter.components;
    return components.filter(component => component.type === name)[0];
  }

  const CoverImageContent = getComponent("coverImage");
  const TextBlock1Content = getComponent("textBlock1");
  const TextBlock2Content = getComponent("textBlock2");
  const PullQuote1Content = getComponent("pullQuoteSmall1");
  const PullQuote2Content = getComponent("pullQuoteSmall2");
  const MultiFrameContent = getComponent("multiFrame");
  const SingleImageWaveContent = getComponent("singleImageWave");


  return (
    <Main>
      <Helmet>
        <body className="overview-page" />
      </Helmet>
      <CoverImage
        image={CoverImageContent.image}
        headline={CoverImageContent.headline}
        subhead={CoverImageContent.subhead}
        hero="hero"
      />
      <Section customClass={["color-block", "bg-yellow"]}>
        <InnerContainer customClass="pull-quote">
          <ThreeStar/>
          <h3 className="pull-quote__body large">{TextBlock1Content.body[0].line}</h3>
          <p className="pull-quote__body">{TextBlock1Content.body[1].line}</p>
        </InnerContainer>
      </Section>
      <Section>
        <InnerContainer customClass="text-block">
          <svg className="logo-framed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
            <rect className="logo-framed__background" width="1920" height="1080"/>
            <path className="tunein" d="M316.89,657.33h37.67v66.45h38.22v31.37H316.89Z"/>
            <path className="tunein" d="M403.06,657.33h37.68v97.82H403.06Z"/>
            <path className="tunein" d="M447.86,657.33h38.77l12.05,62.75H499l12.33-62.75h38.22L525,755.15H472.66Z"/>
            <path className="tunein" d="M554.31,657.33h79.86v28.5H592v10.41h42.19v22.88H592v10.41h42.19v25.62H554.31Z"/>
            <path className="tunein" d="M647.46,687.47c-1.36-8.35-3-17.81-3-22.19,0-8.22,3.29-10.41,12.47-10.41h20.82c9.18,0,12.47,2.19,12.47,10.41,0,4.38-1.65,13.84-3,22.19l-6.3,34.66H653.77Zm2.47,52.47c0-8.08,3.15-10.41,13.56-10.41H671c10.55,0,13.7,2.33,13.7,10.41v4.8c0,8.08-3.15,10.41-13.7,10.41h-7.54c-10.41,0-13.56-2.33-13.56-10.41Z"/>
            <path className="tunein" d="M746.65,729.8c4.52.83,13,2.2,34,2.2,7.8,0,11.5-1.1,11.5-6.58s-1.78-6.71-9.86-6.71h-8.63c-17.67,0-30.83-3.15-30.83-32.2,0-27.94,21.38-31.64,43.29-31.64,14.39,0,31.24,2,39.73,3.29v29c-9.18-.69-19.18-2.06-31.78-2.06-11.37,0-14.52,1.79-14.52,6.85,0,3.7,1.92,5.89,10.27,5.89,29.59,0,39.05,3.7,39.05,28,0,28.09-16.85,31.78-41.1,31.78a343.84,343.84,0,0,1-41.1-2.74Z"/>
            <path className="tunein" d="M931.73,723.78c0,26.57-17.67,33.83-48.77,33.83s-48.77-7.26-48.77-33.83V657.33h37.68v65.08c0,8.22,5.34,9.59,11.09,9.59s11.1-1.37,11.1-9.59V657.33h37.67Z"/>
            <path className="tunein" d="M939.55,657.33h42.74l21.92,54.12h.27L1002,657.33h35.07v97.82H998l-25.62-59.87h-.27l2.47,59.87H939.55Z"/>
            <path className="tunein" d="M1156.41,740.08h-28.22L1125,755.15h-37.67l27.4-97.82h56.3l26.72,97.82h-38.23Zm-14-66.58h-.27l-8.91,41.92h18.08Z"/>
            <path className="tunein" d="M1297.38,723.78c0,26.57-17.67,33.83-48.77,33.83s-48.77-7.26-48.77-33.83V657.33h37.68v65.08c0,8.22,5.34,9.59,11.09,9.59s11.1-1.37,11.1-9.59V657.33h37.67Z"/>
            <path className="tunein" d="M1395.75,749.12a100.28,100.28,0,0,1-41.1,8.49c-37.67,0-51-18.35-51-54.79,0-36,18-48,51-48,13.56,0,28.63,3.15,40,6.71v31.24c-7.39-1.65-21-3.84-32.05-3.84-14.39,0-19.32,5.34-19.32,22.47,0,14.11,3.15,20.55,12.19,20.55a31.05,31.05,0,0,0,5.48-.55V707.06h34.8Z"/>
            <path className="tunein" d="M1434,730.49c5.07,2.19,17.54,3.42,25.07,3.42,8.5,0,14.25-.27,14.25-8.63,0-6.85-2.6-9.45-13.83-9.45a166.91,166.91,0,0,0-17.13,1V705.42L1470,686.1v-.27h-34.93v-28.5h74.66v27.4L1482.88,703l.28.28a58.61,58.61,0,0,1,8.22-.69c13,0,21.5,6.44,21.5,23.84,0,26.3-17.26,31.23-41.09,31.23-19.73,0-30.28-2.6-37.82-4.38Z"/>
            <path className="tunein" d="M1516,702.82c0-33.16,9.72-48,44.93-48s44.94,14.79,44.94,48c0,36.16-10.28,54.79-44.94,54.79S1516,739,1516,702.82Zm56,2.6c0-12.6-2.19-17.26-11.1-17.26s-11.09,4.66-11.09,17.26v5.21c0,13.28,2.87,18.49,11.09,18.49s11.1-5.21,11.1-18.49Z"/>
            <path className="vma" d="M588.49,321.16,530.59,379,472.7,321.16h-157v74.28L530.59,610l214.9-214.51V610H909.37V528.81L959.93,579l50.57-50.15V610h26.27c-.21-4.76-.44-16.19-.44-22.37,0-34.36,3-42.22,3-91.74,0-14.81-3.31-18.41-8.09-18.41-5.34,0-14.46,7.35-24.41,14.43-16,11.34-24.54,20.37-34.86,20.37-6.1,0-9.57-2.69-13.44-7.64a17.45,17.45,0,0,1-2.77-8.26c0-3.67,1.46-7.88,7.08-11.27,6.07-3.66,12.79-3,27.2-11.67,17.09-10.31,33.16-19.48,36.45-21.56,5.87-3.09,4.47-7.85,4.47-12.25,0-9.58,8.6-12.9,16-12.9a26.14,26.14,0,0,1,12.52,3.1c2.65-1.47,9.49-5.37,22.4-13.27,8.47-5.18,13-15.29,23.13-15.29,4.22,0,8,.81,11.43,3.59,5,4.09,5.92,6.38,5.92,9.89,0,7.9-12.25,14.47-18.67,18.36-19.81,12-26.08,15-33.26,19.62,1,4.3.32,16.52.32,25.52,0,15,3.35,42.58,3.35,58.08,0,18.41-.76,41.23-.76,46.91a245.81,245.81,0,0,0,1.88,26.76h257l57.89-57.8L1448,610h157V535.67L1390.1,321.16,1175.21,535.67V493.4a336,336,0,0,0-25.06,65.8c-4.83,16.47-6.81,23.52-16.91,23.52-9.18,0-12.26-2-16.5-13.2s-18.61-48.37-19.73-51.33c-7.66-20.23-12.14-35.61-12.14-44.46,0-15.64,11-21.36,21.4-21.36,14.1,0,16.6,13.37,18.52,26,1,6.54,1.63,24.56,2.64,34,.46,4.38,0,8.42,5,8.42,4.51,0,6.5-12.6,9.91-21.45,12-32.17,27.82-62.3,32.89-70.13V321.16H1017.73L959.93,379l-57.78-57.79Z"/>
          </svg>
        </InnerContainer>
      </Section>
      <Section>
        <InnerContainer customClass="pull-quote">
          <Star customClass="star--top"/>
          <p className="pull-quote__body"><q>{PullQuote1Content.body[0].line}</q></p>
          <p className="pull-quote__attr">~{PullQuote1Content.attr}</p>
          <Star customClass="star--bottom"/>
        </InnerContainer>
      </Section>
      <SingleImageWave image={SingleImageWaveContent.image} body={SingleImageWaveContent.body[0].line}/>
      <Section customClass={["color-block", "bg-green"]}>
        <InnerContainer customClass="pull-quote">
          <Star customClass="star--top"/>
          <h3 className="pull-quote__body large">{TextBlock2Content.body[0].line}</h3>
          <p className="pull-quote__body">{TextBlock2Content.body[1].line}</p>
          <Star customClass="star--bottom"/>
        </InnerContainer>
      </Section>
      <CoverImage
        image={MultiFrameContent.image}
        variant="wavy"
      />
      <Section>
        <InnerContainer customClass="pull-quote">
          <Star customClass="star--top"/>
          <p className="pull-quote__body"><q>{PullQuote2Content.body[0].line}</q></p>
          <p className="pull-quote__attr">~{PullQuote2Content.attr}</p>
          <Star customClass="star--bottom"/>
        </InnerContainer>
      </Section>
    </Main>
  );
}

export default Overview
