import React, { useEffect } from "react";
import Section from '../Layout/Section/Section';
import PreviewCompatibleImage from '../Utils/PreviewCompatibleImage';
import Star from '../Dividers/star';
import Wave4 from '../Dividers/wave4';

import "./cover-image.scss"

const CoverImage = ({ image, headline, video, subhead, variant='', hero, logo, id}) => {
  
  const animateCoverImages = () => {
    const coverImage = document.querySelector("section.section.cover-container.hero > div > picture > img");

    if(coverImage) {
      if(coverImage.classList.contains("is-visible")) {
        coverImage.style.position = "fixed";
        coverImage.style.top = -(document.body.dataset.scrollStatus*5)+'px';
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', animateCoverImages, false);
  });

  function renderImageOrVideo(image=null, video=null) {
    if (image) {
      return <PreviewCompatibleImage customClassName="cover__image" imageInfo={image} alt="cover image" />
    } else if (video) {
      return <video controls width="100%"><source src={video} type="video/mp4" /><track/></video>
    }
  }

  if (variant === "multiframe") {
    return (
      <Section id={id} customClass={["cover-container", hero ? hero : '']}>
        <span className="frame frame--1">
          <span className="frame frame--2">
            <span className="frame frame--3">
                <span className="cover-text-container">
                  {headline ? <h1 className="cover-headline animate-on-scroll">{headline}</h1> : ''}
                  <p className="cover-body">{subhead}</p>
                </span>
                <PreviewCompatibleImage customClassName="cover__image" imageInfo={image} alt="cover image" />
            </span>
          </span>
        </span>
      </Section>
    )
  } else if (variant === "singleFrame") {
    return (
      <Section id={id} customClass={["cover-container", "singleFrame"]}>
        <span className="cover-text-container">
        {headline ? <h1 className="cover-headline animate-on-scroll">{headline}</h1> : ''}
          {subhead ? <p className="cover-body">{subhead}</p> : ''}
        </span>
        {renderImageOrVideo(image, video)}
      </Section>
    )
  } else if (variant === "wavy") {
    return (
      <Section id={id} customClass={["cover-container", "wavy"]}>
        <Wave4/>
        <span className="cover-text-container">
          {headline ? <h1 className="cover-headline animate-on-scroll">{headline}</h1> : ''}
          {subhead ? <p className="cover-body">{subhead}</p> : ''}
        </span>
        <PreviewCompatibleImage customClassName="cover__image" imageInfo={image} alt="cover image" />
      </Section>
    )
  }

  return (
      <Section id={id} customClass={["cover-container", hero ? hero : '']}>
        <Star customClass={["star--with-wave"]} />
        <svg className="wave-with-star animate-on-scroll wipe-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 154">
          <path id="Path-3" className="cls-1" d="M-1.4,68-2,154.18H1442V125.5l-218.12,17.78H1002.61L517,38.5Z"/>
          <path id="Combined-Shape" className="cls-2" d="M1442,132.61c-277.42,34.89-480.1,27.45-680-22.32-291.26-67.05-547.26-67.05-764,0h0V45.12C256.32-16.56,504.93-10.5,739.81,63.31s445,92.19,702.19,55.11Z"/>
        </svg>
        <span className={`cover-text-container ${logo ? "with-logo" : ''}`}>
          {logo ? <Star customClass="star--top"/> : ''}
          {headline ? <h1 className="cover-headline animate-on-scroll">{headline}</h1> : ''}
          {subhead ? <p className="cover-body">{subhead}</p> : ''}
          {logo ? <PreviewCompatibleImage customClassName="partner-logo" imageInfo={logo} alt="partner logo" />: ''}
          {logo ? <Star customClass="star--bottom"/> : ''}
        </span>
        <PreviewCompatibleImage customClassName="cover__image" imageInfo={image} alt="cover image" />
      </Section>
  )
};

export default CoverImage;
