import React from 'react';
import Wave from '../Dividers/wave';
import Section from '../Layout/Section/Section';
import PreviewCompatibleImage from '../Utils/PreviewCompatibleImage';

const SingleImageWave = ({image, body}) => {
  return (
    <Section>
      <div className="single-image-wave">
        <PreviewCompatibleImage customClassName="single-image-wave__image" imageInfo={image} alt="cover image" />
        <Wave/>
      </div>
      <p className="single-image-wave__body">{body}</p>
    </Section>
  )
}

export default SingleImageWave
