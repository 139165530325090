import React from 'react';
import Star from './star';

const ThreeStar = () => {
  return (
    <div className="three-star">
      <Star customClass="threeStar--star1"/>
      <Star customClass="threeStar--star2"/>
      <Star customClass="threeStar--star3"/>
    </div>
  )
}

export default ThreeStar
